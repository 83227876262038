Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("./libs/util");
var consumer_1 = require("./consumer");
var root = util_1.getRootScope();
exports.service = root.JSBridgeService; // 获取 bridge service 对象，由 native 提供
exports.consumer = new consumer_1.Consumer(); // 创建 bridge consumer 对象，由 js 提供
// 挂载 JSBridgeConsumer
root.JSBridgeConsumer = {
    applyCallback: exports.consumer.applyCallback.bind(exports.consumer),
    emit: exports.consumer.emit.bind(exports.consumer),
};
// service 调用超时时间
exports.timeout = 10 * 1000; // 接口调用超时时间
let retryTime = 0
/**
 * 调用 service 处理器
 * @param handlerName 处理器名称
 * @param handlerArgs 处理器所需参数
 */
exports.invoke = function (handlerName, handlerArgs) {
    // 检测 Native 是否注入 JSBridgeService
    var webkit = root.webkit
    if (webkit) {
      var messageHandlers = webkit.messageHandlers
      if (messageHandlers) {
        exports.iosService = messageHandlers.JSBridgeService  // IOS版本
      }
    }
    
    if (!exports.service && !exports.iosService) {
      if (retryTime >= 50) {
        const err = new Error('js bridge service not found.');
        return Promise.reject(err);
      } else {
        retryTime += 1
        console.log('[JSBridge retry] ' + retryTime)

        // 偶尔会出现JSBridge注入未完成但页面已经调用方法导致异常的问题，这里延迟1秒，尝试50次，每次间隔20毫秒
        // 如果1分钟还没注入完成，才会提示js bridge service not found.
        return new Promise((resolve, reject) => setTimeout(() => {
          exports.invoke(handlerName, handlerArgs).then(resolve).catch(reject)
        }, 20))
      }
    }
    if (exports.iosService && !exports.iosService.postMessage) {
        const err = new Error('js bridge service invoke method not found.');
        return Promise.reject(err);
    }
    if (exports.service && !exports.service.invoke) {
      const err = new Error('js bridge service invoke method not found.');
      return Promise.reject(err);
    }

    // 返回 promise
    return new Promise(function (resolve, reject) {
        var timeId = setTimeout(function () {
            reject(new Error('js bridge service invoke timeout'));
        }, exports.timeout);
        var callback = function (res) {
            clearTimeout(timeId);
            if (!res.success) {
                var err = new Error("[" + handlerName + "] " + res.errorMessage);
                err.code = res.errorCode;
                reject(err);
                return;
            }
            resolve(res.data);
        };
        var handlerCallbackName = createCallbackName();
        exports.consumer.addCallback(handlerCallbackName, callback);
        // 执行 service 调用

        if (exports.service) {
          try {
            exports.service.invoke(handlerName, JSON.stringify(handlerArgs || {}), handlerCallbackName);
          }
          catch (err) {
              reject(err);
          }
        }
        
        if (exports.iosService) {
          try {
            exports.iosService.postMessage({
              method: handlerName,
              args: JSON.stringify(handlerArgs || {}),
              callback: handlerCallbackName
            });
          }
          catch (err) {
              reject(err);
          }
        }
    });
};
/**
 * 生成回调函数名称
 */
var createCallbackName = function () {
    return "__jsbridge_callback_" + util_1.uuid();
};
/**
 * 生成事件名称
 */
var creatEventName = function () {
    return "__jsbridge_event_" + util_1.uuid();
};
/**
 * 封装事件
 * @param eventCallbackMap 事件集合
 */
exports.createEvents = function (eventCallbackMap) {
    var eventNames = Object.keys(eventCallbackMap);
    var events = {};
    eventNames.forEach(function (item) {
        var callback = eventCallbackMap[item];
        if (callback && typeof callback === 'function') {
            events[item] = creatEventName();
            exports.consumer.on(events[item], callback);
        }
    });
    return events;
};
