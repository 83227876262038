import { consumer } from '../../base';
/**
 * 全局事件绑定
 */
export var on = consumer.on.bind(consumer);
/**
 * 全局事件移除
 */
export var off = consumer.off.bind(consumer);
/**
 * 全局事件触发
 */
export var emit = consumer.emit.bind(consumer);
// 全局事件列表
/**
 * Network Change Event
 * bridge.on('networkChange', (event: NetworkInfo) => {
 *  event.type // WIFI
 * })
 */
