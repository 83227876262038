import { useReducer, createContext, useState, useRef, useContext } from 'react';
import { Toast } from 'antd-mobile';
import * as JSBridge from 'libs/jsbridge';
import * as SystemLog from 'libs/log';
import { formatDate, formatDuration, getUrlQueries, compressImage, dataURItoBlob } from 'libs/helper';
import { IMedia } from '_';
import {
  getOssPolicy, 
  getGetUserInfo, 
  getGetFriendsList,
  getGetBroadcasterCoins,
  postSearchCoinFlow,
  postGetBroadcasterStatistics,
  postUpdateMedia
} from 'native/user';
import { useWebView } from 'component/webview';
import { useTranslation } from 'react-i18next';

const {
  REACT_APP_TEMP_TOKEN,
  REACT_APP_DEFAULT_AVATAR,
  REACT_APP_DEFAULT_GENDER,
  REACT_APP_DEFAULT_USERID,
  REACT_APP_DEFAULT_USERAGE,
  REACT_APP_DEFAULT_USERTYPE,
  REACT_APP_DEFAULT_NICKNAME,
  REACT_APP_DEFAULT_BIRTHDAY,
  REACT_APP_DEFAULT_LANGUAGE,
  REACT_APP_DEFAULT_ABOUT
} = process.env

export interface IUser {
  userId:string
  nickname:string
  avatar:string
  avatarThumbUrl:string
  avatarUrl:string
  age:string
  gender:1|2
  userType:number
  token:string
  birthday:string
  about:string
  mediaList:IMedia[]
  language:string
  coins:number
  availableCoins:number
  friendList:any[]
  country:string
  isRobot:boolean
  isAnswer:boolean
  tagsList:string[]
  isFriend:boolean
  isVip:boolean
  vipExpiryTime:string
  status:number,
  grade:number
}

export const initState:IUser = {
  userId: REACT_APP_DEFAULT_USERID || '',
  nickname: REACT_APP_DEFAULT_NICKNAME || '',
  avatar: REACT_APP_DEFAULT_AVATAR || '',
  avatarThumbUrl: REACT_APP_DEFAULT_AVATAR || '',
  avatarUrl: REACT_APP_DEFAULT_AVATAR || '',
  age: REACT_APP_DEFAULT_USERAGE || '',
  gender: +(REACT_APP_DEFAULT_GENDER || 1) === 2 ? 2 : 1,
  userType: +(REACT_APP_DEFAULT_USERTYPE || 0),
  token: REACT_APP_TEMP_TOKEN || '',
  birthday: REACT_APP_DEFAULT_BIRTHDAY || '',
  about: REACT_APP_DEFAULT_ABOUT || '',
  mediaList: [],
  language: REACT_APP_DEFAULT_LANGUAGE || '',
  coins: 0,
  availableCoins: 0,
  friendList: [],
  country: 'USA',
  isRobot: false,
  isAnswer: false,
  tagsList: [],
  isFriend: false,
  isVip: false,
  vipExpiryTime: '',
  status: 0,
  grade: 0
}

const reducer = (
  state: typeof initState,
  action: { type: string; [propName: string]: any }
) => {
  switch (action.type) {
    default:
      return state;
    case 'login':
      return {
        ...state,
        ...action.value
      }
    case 'logout':
      return {
        ...state,
        ...initState
      }
    case 'profile':
      return {
        ...state,
        ...action.value
      }
  }
}

export function useUser() {
  return useReducer(reducer, initState)
}

export const UserContext = createContext<{ state: typeof initState, dispatch: any }>({
  state: initState, dispatch: null
})

type Result = {code:number,data?:IUser,msg:string}

type INativeUser = {
  userId?:string
  nickname?:string
  avatar?:string
  avatarUrl?:string
  gender?:boolean
}

export async function getOssInfo() {
  return await getOssPolicy()
}

/**
 * 获取用户的虚拟资产
 */

type IIconObject = {
  todayIncomeCoins:string
  totalCoins:string
  availableCoins:string
  withdrawCoins:string
}

const initIconObject:IIconObject = {
  todayIncomeCoins: 'loading',
  totalCoins: 'loading',
  availableCoins: 'loading',
  withdrawCoins: 'loading'
}

export function useUserAssets() {
  const { t } = useTranslation()
  const [ assets, setAssets ] = useState(initIconObject)
  const loading = useRef<boolean>(false)

  const dataNull = {
    todayIncomeCoins: '-',
    totalCoins: '-',
    availableCoins: '-',
    withdrawCoins: '-'
  }

  async function fetchUserAssets() {
    loading.current = true
    const { code, data } = await getGetBroadcasterCoins()
    if (code) {
      setAssets(dataNull)
      Toast.fail(t('message.error.serverFailed'), 3, () => {}, true)
      return { loading, assets, fetchUserAssets }
    }
    setAssets({
      ...dataNull,
      ...data as IIconObject
    })
    loading.current = false
  }

  return { loading, assets, fetchUserAssets }
}

/**
 * 获取用户流水
 */

interface IUseUserFlowOptions {
  reset:boolean
  limit:number
}

interface ICard {
  title:string
  coins:number
  createTime:string
}

export interface IGiftItem {
  giftCode:string
  giftNum:number
}

export interface IGiftCard extends ICard {
  giftList:IGiftItem[]
}

export interface IPrivateCallCard extends ICard {
  duration:string
}
export interface IRandomMatchCard extends ICard {
  duration:string
}
export interface IWithdrawCard extends ICard {}
export interface IFraudCard extends ICard {
  categoryDesc:string
}
export interface IBonusCard extends ICard {
  categoryDesc:string
}
export interface IOtherCard extends ICard {
  categoryDesc:string
}

type IFlowItem = 
  { type: 'gift' } & IGiftCard |
  { type: 'call' } & IPrivateCallCard |
  { type: 'match' } & IRandomMatchCard |
  { type: 'withdraw' } & IWithdrawCard |
  { type: 'fraud' } & IFraudCard |
  { type: 'bonus' } & IBonusCard |
  { type: 'other' } & IOtherCard

const recordTypeSet:any = {
  '100001-1': 'call',
  '100001-2': 'match',
  '100001-3': 'call',
  '100002-0': 'gift',
  '100004-0': 'withdraw',
  '100008-0': 'fraud',
  '100012-0': 'bonus',
  '100014-0': 'bonus',
  '100016-0': 'bonus'
}

export function useUserFlow() {
  const { t } = useTranslation()
  const [ loading, setLoading ] = useState<boolean>(false)
  const loadMore = useRef<boolean>(loading)
  const flow = useRef<IFlowItem[]>([])
  const page = useRef<number>(1)
  const hasMore = useRef<boolean>(true)
  const income = useRef<string>('loading')
  const withdraw = useRef<string>('loading')
  loadMore.current = loading

  async function fetchUserFlow(time:string, options:IUseUserFlowOptions) {
    setLoading(true)
    
    const { limit, reset } = options
    if (reset) {
      flow.current = []
      page.current = 1
    }
    const beginTime = time + '-01'
    const params = {
      beginTime,
      page: page.current,
      limit
    }
    
    const { code, data, msg } = await postSearchCoinFlow(params)
    if (code) {
      income.current = income.current === 'loading' ? '-' : income.current
      withdraw.current = withdraw.current === 'loading' ? '-' : withdraw.current
      setLoading(false)
      Toast.fail(t('message.error.serverFailed'), 3, () => {}, true)
      return { loadMore, hasMore, flow, fetchUserFlow, income, withdraw }
    }

    const { records, totalPages, extra } = data
    const formattedRecords = records.map((record:any) => {
      const key = `${record.category}-${record.callType || 0}`
      record.type = recordTypeSet[key]
      record.title = record.title || record.categoryDesc || ''
      record.duration = record.duration ? formatDuration(+record.duration) : ''
      record.createTime = record.createTime ? formatDate(new Date(+record.createTime), 'm-dd hh:ii') : ''
      if (record.category === 100002) {
        record.giftList = [{
          giftCode: record.giftCode,
          giftNum: record.giftNum
        }]
      }
      return record
    })
    flow.current = flow.current.concat(formattedRecords)
    page.current = page.current + 1
    hasMore.current = page.current <= totalPages

    const { totalIncomeCoins, totalWithdrawCoins } = extra
    income.current = totalIncomeCoins || 0
    withdraw.current = totalWithdrawCoins || 0
    setLoading(false)
  }

  return { loadMore, hasMore, flow, fetchUserFlow, income, withdraw }
}

/**
 * 获取用户收入分析
 * 
 */

interface IUserStatistics {
  onlineTime:string //在线时长 ,
  randomMatchConnectionRatio:string //匹配接通率 ,
  privateCallConnectionRatio:string //匹配通话总时长 ,
  randomMatchCallTotalTime:string // 收到礼物次数
  privateCallTotalTime:string // 通话收入
  privateCallIncomeCoins:string // 收到礼物总价值 ,
  giftsReceivedNum:string,
  giftsReceivedTotalValue:string,
  // incomingCallConnectionNum:string //被叫接通数 ,
  // incomingCallConnectionRatio:string //被叫接通率 ,
  // incomingCallNum:string //被叫次数 ,
  
  // outgoingCallConnectionNum:string //主叫接通数 ,
  // outgoingCallConnectionRatio:string //主叫接通率 ,
  // outgoingCallNum:string //主叫次数 ,
  // privateCallAverageTime:string //私人通话平均时长 ,
  // privateCallTotalTime:string //私人通话总时长 ,
  // randomMatchCallAverageTime:string //匹配通话平均时长 ,
  // randomMatchConnectionNum:string //匹配接通数 ,
  // randomMatchNum:string //匹配次数
}

const initUserStatistics = {
  onlineTime: 'loading...',
  randomMatchConnectionRatio: 'loading...', 
  privateCallConnectionRatio: 'loading...',
  randomMatchCallTotalTime: 'loading...',
  privateCallTotalTime: 'loading...',
  privateCallIncomeCoins: 'loading...',
  giftsReceivedNum: 'loading...',
  giftsReceivedTotalValue: 'loading...',
  // incomingCallConnectionNum: 'loading...', //被叫接通数 ,
  // incomingCallConnectionRatio: 'loading...', //被叫接通率 ,
  // incomingCallNum: 'loading...', //被叫次数 ,
  // outgoingCallConnectionNum: 'loading...', //主叫接通数 ,
  // outgoingCallConnectionRatio: 'loading...', //主叫接通率 ,
  // outgoingCallNum: 'loading...', //主叫次数 ,
  // privateCallAverageTime: 'loading...', //私人通话平均时长 ,
  // privateCallTotalTime: 'loading...', //私人通话总时长 ,
  // randomMatchCallAverageTime: 'loading...', //匹配通话平均时长 ,
  // randomMatchConnectionNum: 'loading...', //匹配接通数 ,
  // randomMatchNum: 'loading...' //匹配次数
}

export type IUserStatisticsFields = keyof IUserStatistics

const userStatisticsFields = Object.keys(initUserStatistics) as IUserStatisticsFields[]

export function useUserStatistics() {
  const { t } = useTranslation()
  const [ statistics, setStatistics ] = useState<IUserStatistics>(initUserStatistics)
  
  const dataNull = (Object.keys(initUserStatistics) as IUserStatisticsFields[]).reduce<IUserStatistics>((statistics, key) => {
    statistics[key] = '-'
    return statistics
  }, Object.create(initUserStatistics))

  async function fetchUserStatistics(time:string) {
    setStatistics(initUserStatistics)

    const beginTime = time + '-01'    
    const { code, data, msg } = await postGetBroadcasterStatistics({ beginTime })
    if (code) {
      setStatistics(dataNull)
      Toast.fail(t('message.error.serverFailed'), 3, () => {}, true)
      return
    }

    setStatistics({
      ...dataNull,
      ...data,
      onlineTime: formatDuration(data.onlineTime),
      randomMatchConnectionRatio: data.randomMatchConnectionRatio + '%', 
      privateCallConnectionRatio: data.privateCallConnectionRatio + '%',
      randomMatchCallTotalTime: formatDuration(data.randomMatchCallTotalTime),
      privateCallTotalTime: formatDuration(data.privateCallTotalTime)
    })
  }

  return { statistics, fetchUserStatistics, userStatisticsFields }
}

/**
 * 获取用户信息
 */

const initUserInfo:IUser = {
  userId: REACT_APP_DEFAULT_USERID || '',
  nickname: REACT_APP_DEFAULT_NICKNAME || '',
  avatar: REACT_APP_DEFAULT_AVATAR || '',
  avatarThumbUrl: REACT_APP_DEFAULT_AVATAR || '',
  avatarUrl: REACT_APP_DEFAULT_AVATAR || '',
  age: REACT_APP_DEFAULT_USERAGE || '-',
  gender: +(REACT_APP_DEFAULT_GENDER || 0) === 2 ? 2 : 1,
  userType: +(REACT_APP_DEFAULT_USERTYPE || 1),
  token: REACT_APP_TEMP_TOKEN || '',
  birthday: REACT_APP_DEFAULT_BIRTHDAY || '',
  about: REACT_APP_DEFAULT_ABOUT || '',
  mediaList: [],
  language: REACT_APP_DEFAULT_LANGUAGE || '',
  coins: 0,
  availableCoins: 0,
  friendList: [],
  country: '',
  isRobot: false,
  isAnswer: false,
  tagsList:[],
  isFriend: false,
  isVip: false,
  vipExpiryTime: '',
  status: 0,
  grade: 0
}

export function useUserInfo() {
  const [ user, setUser ] = useState<IUser>(initUserInfo)
  const refUser = useRef<IUser>(user)
  refUser.current = user

  function handleNativeResult(result:string) {
    SystemLog.info(SystemLog.LogTypes.Web, result)

    if (!result) return { code: -1, msg: 'Native error' }

    const { token, userInfo } = (typeof result === 'string' ? JSON.parse(result) : result)
    return { code: 0, data: { ...userInfo, token }, msg: '' }
  }

  function refreshUserProfile(cache:Result|null, force:boolean) {
    return force || !cache || !cache.data
      ? new Promise<Result>((resolve, reject) => {
        JSBridge.tryFetchAccount().catch(reject)
        JSBridge.on('accountUpdate', (data:string) => {
          SystemLog.info(SystemLog.LogTypes.JSBridge, '[accountUpdate]', data)
          const result = handleNativeResult(data)
          resolve(result)
        })
      })
      .catch(error => {
        SystemLog.error(SystemLog.LogTypes.JSBridge, '[error]', error.message)
        return (getGetUserInfo() as Promise<Result>)
      })
      : Promise.resolve(cache)
  }

  async function fetchUserInfo(force:boolean) {
    const promise = force ? refreshUserProfile(null, force) : JSBridge.getCurrentAccountInfo()
      .catch(error => {
        SystemLog.error(SystemLog.LogTypes.JSBridge, error.message, 'getCurrentAccountInfo')
        return ''
      })
      .then(handleNativeResult)
      .then((result:Result) => refreshUserProfile(result, force))
    
    const { code, data }:Result = await promise
    if (code || !data) return
    
    const userChangeCode = isUserChange(data, refUser.current)
    switch(userChangeCode) {
      case 1:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'basic-info-change', user, data)
        break
      case 2:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'media-change', user, data)
        break
      case 3:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'avatar-expires', user, data)
        break
      case 4:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'media-expires', user, data)
        break
      default:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'nothing-change')
        return data
    }

    data && setUser(data)
    return data
  }

  return { user, setUser, fetchUserInfo, refreshUserProfile }
}

/**
 * 获取好友列表 
 */

export interface IUserFriend {
  userId:string
  userType:number
  age:string
  country:string
  gender:number
  language:string
  nickname:string
  avatarUrl:string
  isVip:boolean
}

const userFriendsCacheKey = 'cache:user:friends'

export function useUserFriends() {
  const userFriends = useRef<IUserFriend[]>([])
  const [ friends, setFriends ] = useState<IUserFriend[]>(getUserFriendsFromCache())
  userFriends.current = friends

  function getUserFriendsFromCache() {
    const cache = localStorage.getItem(userFriendsCacheKey)
    if (!cache) return []

    let userFriends = []
    try {
      userFriends = JSON.parse(cache)
    } catch(e) {
      SystemLog.error(SystemLog.LogTypes.Web, 'getUserFriendsFromCache', e.message)  
    }
    return userFriends as IUserFriend[]
  }

  function setUserFriendsToCache(data:IUser[]) {
    const cacheUserFriends:IUserFriend[] = data.map(
      ({ userId, userType, age, gender, country, language, nickname, avatarUrl, isVip }) => 
      ({ userId, userType, age, gender, country, language, nickname, avatarUrl, isVip })
    )
    const cache = JSON.stringify(cacheUserFriends)
    localStorage.setItem(userFriendsCacheKey, cache)
  }

  async function fetchUserFriends() {
    const { code, data } = await getGetFriendsList()
    if (code) return

    const oldIds = userFriends.current.map(({ userId }) => userId)
    const newIds = (data as IUser[]).map(({ userId }) => userId)
    if (JSON.stringify(oldIds) !== JSON.stringify(newIds)) {
      setFriends(data)
      setUserFriendsToCache(data)
    }

    return data
  }

  return { friends, fetchUserFriends }
}

/**
 * 获取用户信息
 */

const initUserFriendInfo:IUser = {
  userId: '',
  nickname: 'Loading...',
  avatar: '',
  avatarThumbUrl: '',
  avatarUrl: '',
  age: '',
  gender: 1,
  userType: 1,
  token: '',
  birthday: '',
  about: '',
  mediaList: [],
  language: 'Loading...',
  coins: 0,
  availableCoins: 0,
  friendList: [],
  country: '',
  isRobot: false,
  isAnswer: false,
  tagsList: [],
  isFriend: true,
  isVip: false,
  vipExpiryTime: '',
  status: 0,
  grade: 0
}

export function useUserFriendInfo() {
  const { getUserInfo } = useWebView();
  const [ friend, setFriend ] = useState<IUser>(initUserFriendInfo)
  const refFriend = useRef<IUser>(friend)
  refFriend.current = friend

  function handleNativeResult (result:string) {
    SystemLog.info(SystemLog.LogTypes.Web, result)
    
    if (!result) return { code: 0, data: {}, msg: 'no data response' }
    
    const data = (typeof result === 'string' ? JSON.parse(result) : result) as INativeUser
    return { code: 0, data, msg: '' }
  }

  async function fetchUserFriendInfo(userId:string) {
    const { data:cacheData } = await getUserInfo(userId, false).then(handleNativeResult)
    if (cacheData && cacheData.userId) {
      setFriend({
        about: '',
        ...cacheData as IUser 
      })
    }
    const { code, data, msg } = await getUserInfo(userId, true).then(handleNativeResult)
    // const { code, data, msg } = await getGetUserInfo({ userId })
    if (code || !data.userId) {
      SystemLog.error(SystemLog.LogTypes.Server, 'getUserInfo', msg)
      setFriend({
        ...initUserFriendInfo,
        nickname: '',
        birthday: '',
        about: '',
        language: '',
        country: ''
      })
      return
    }
    
    const friend = {
      about: '',
      ...data as IUser 
    }

    const userChangeCode = isUserChange(friend, refFriend.current)
    switch(userChangeCode) {
      case 1:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'basic-info-change', refFriend.current, friend)
        break
      case 2:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'media-change', refFriend.current, friend)
        break
      case 3:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'avatar-expires', refFriend.current, friend)
        break
      case 4:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'media-expires', refFriend.current, friend)
        break
      default:
        SystemLog.info(SystemLog.LogTypes.Web, 'fetch', 'nothing-change')
        return friend
    }

    setFriend(friend)
    return friend as IUser
  }
  return { friend, fetchUserFriendInfo }
}

/**
 * 判定用户信息是否变更
 */

export function isUserChange(newUser:IUser, user:IUser) {
  const keyFields:(keyof IUser)[] = [
    'about', 'age', 'birthday', 'avatar',
    'country', 'gender', 'isAnswer',
    'language', 'nickname', 'userId', 'userType',
    'availableCoins'
  ]
  const oldMediaIdList = (user.mediaList || []).map(({ mediaId }) => mediaId)
  const newMediaIdList = (newUser.mediaList || []).map(({ mediaId }) => mediaId)
  const avatarExpireTime = user.avatarThumbUrl
    ? user.avatarThumbUrl.indexOf('Expires') > -1
      ? +getUrlQueries(user.avatarThumbUrl)['Expires'] * 1000
      : Number.MAX_SAFE_INTEGER
    : Number.MAX_SAFE_INTEGER
  const mediaExpireTimeList = (user.mediaList || []).map(({ mediaUrl }) => {
    return mediaUrl
    ? mediaUrl.indexOf('Expires') > -1
      ? +getUrlQueries(mediaUrl)['Expires'] * 1000
      : Number.MAX_SAFE_INTEGER
    : Number.MAX_SAFE_INTEGER
  })
  // 基本信息更改
  if (keyFields.some((field:keyof IUser) => user[field] !== newUser[field])) {
    return isUserChange.ChangeType.BasicInfoChange
  }
  // 图片更改
  if (JSON.stringify(oldMediaIdList) !== JSON.stringify(newMediaIdList)) {
    return isUserChange.ChangeType.MediaChange
  }
  // 头像过期
  if (avatarExpireTime < Date.now()) {
    return isUserChange.ChangeType.AvatarExpires
  }
  // 照片过期
  if (mediaExpireTimeList.some(expireTime => expireTime < Date.now())) {
    return isUserChange.ChangeType.mediaExpires
  }

  return 0
}

isUserChange.ChangeType = {} as any
isUserChange.ChangeType.BasicInfoChange = 1
isUserChange.ChangeType.MediaChange = 2
isUserChange.ChangeType.AvatarExpires = 3
isUserChange.ChangeType.mediaExpires = 4

export async function uploadImage (file:File) {
  const retPolicy = await getOssInfo()
  if (retPolicy.code) {
    return retPolicy
  }

  const dataUrl = await compressImage(file)
  const compressedFile = dataURItoBlob(dataUrl)
  const {
    accessKeyId,
    signature,
    policy,
    host,
    dir,
    callback
  } = retPolicy.data || {}
  const form = new FormData();
  form.append('ossaccessKeyId', accessKeyId || '');
  form.append('policy', policy || '');
  form.append('signature', signature || '');
  form.append('key', `${dir}${(Date.now() + ~~(Math.random() * 1000000)) + '.' + file.name.split('.').pop()}`);
  form.append('callback', callback || '');
  form.append('file', compressedFile);

  const retOss = await new Promise<{code:number,data:any,msg:string}>((resolve, reject) => JSBridge.netRequestPost({
    host,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    body: form,
    onResponse: (...args) => {
      SystemLog.log(SystemLog.LogTypes.JSBridge, args)
  
      const [ response ] = args
      resolve(response)
    },
    onError: () => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, host, 'error')
  
      reject(new Error('unknown'))
    }
  }).catch(reject)).catch(error => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    return { code: -1, data:null, msg: error && error.message || 'unknown error'  }
  })
  if (retOss.code) {
    return retOss
  }
  const fileBag = retOss.data
  if (!fileBag || !fileBag.responseData || typeof fileBag.responseData === 'string') {
    fileBag && SystemLog.error(SystemLog.LogTypes.Server, fileBag.responseData)
    return { code: -1, data:null, msg: fileBag.responseData }
  }
  if (fileBag.responseData.code && fileBag.responseData.msg) {
    SystemLog.error(SystemLog.LogTypes.Server, fileBag.responseData.msg)
    return fileBag.responseData
  }

  const { responseData: { data: { filename } } } = fileBag
  const retServer = await postUpdateMedia({
    actionType: 1,
    mediaPath: filename,
    mediaType: 'photo'
  })
  
  if (retServer.code) {
    SystemLog.error(SystemLog.LogTypes.Server, retServer.msg)
    return retServer
  }

  return { code: 0, msg: 'Success' }
}
