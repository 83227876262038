import { uuid } from '../libs/util';
import { invoke, createEvents } from '../../base';
/**
 * 获取当前登录用户
 */
export var getCurrentLoggedUser = function () {
    return invoke('biz.getCurrentLoggedUser');
};
/**
 * 创建Native文本输入框
 */
export var createNativeTextInput = function (args) {
    var id = uuid();
    var placeholder = args.placeholder, value = args.value, onShow = args.onShow, onHide = args.onHide, onSubmit = args.onSubmit;
    var wrapArgs = {
        id: id,
        placeholder: placeholder,
        value: value,
        events: createEvents({ onShow: onShow, onHide: onHide, onSubmit: onSubmit }),
    };
    // 创建实例对象
    var instance = {
        show: function () {
            return invoke('biz.nativeTextInput.show', { id: id });
        },
        hide: function () {
            return invoke('biz.nativeTextInput.hide', { id: id });
        },
        setValue: function (value) {
            return invoke('biz.nativeTextInput.setValue', { id: id, value: value });
        },
        destroy: function () {
            return invoke('biz.nativeTextInput.destroy', { id: id });
        },
    };
    // 创建成功后返回实例对象
    return invoke('biz.nativeTextInput.create', wrapArgs).then(function () { return instance; });
};
/**
 * 获取UC公参
 */
export var getParams = function (args) {
    return invoke('biz.getParams', args);
};
/**
 * 创建作者信息条
 */
export var createAuthorBar = function (args) {
    var visible = args.visible, userId = args.userId, nickname = args.nickname, avatarUrl = args.avatarUrl, level = args.level, followStatus = args.followStatus, onFollowBtnClick = args.onFollowBtnClick;
    var wrapArgs = {
        visible: visible,
        userId: userId,
        nickname: nickname,
        avatarUrl: avatarUrl,
        level: level,
        followStatus: followStatus,
        events: createEvents({ onFollowBtnClick: onFollowBtnClick }),
    };
    // 创建AuthorBar实例
    var instance = {
        setState: function (args) {
            return invoke('biz.authorBar.setState', args);
        },
    };
    // 创建成功后返回AuthorBar实例
    return invoke('biz.authorBar.create', wrapArgs).then(function () { return instance; });
};
/**
 * 上报日志
 */
export var sendLog = function (data) {
    return invoke('log.send', data);
};
/**
 * 消息广播 - 发生互动行为
 */
export var broadcastSocialPost = function (args) {
    return invoke('biz.broadcast.socialPost', args);
};
/**
 * 创建右上角菜单
 * 1. 多个项时，折叠成菜单，展开后选中 item 才回调
 * 2. 一个项时
 *    - 如果 id 能命中内置关键字，直接显示 icon，点击马上回调
 *    - 否则，还是菜单
 */
export var createToolbarMenu = function (args) {
    var items = args.items, onOptionsItemSelected = args.onOptionsItemSelected;
    var wrapArgs = {
        items: items,
        events: createEvents({ onOptionsItemSelected: onOptionsItemSelected }),
    };
    return invoke('biz.createToolbarMenu', wrapArgs);
};
/**
 * 优惠券复制成功的 toast 提示，业务定制
 * @since Yoli >= 0.8.0
 */
export var couponCopyToast = function (args) {
    return invoke('biz.couponCopyToast', args);
};
/**
 * 可选的 webview 主题值
 */
export var EnumWebViewTheme;
(function (EnumWebViewTheme) {
    EnumWebViewTheme["THEME_1"] = "1";
    EnumWebViewTheme["THEME_2"] = "2";
    EnumWebViewTheme["THEME_IMMERSIVE_WHITE"] = "3";
    EnumWebViewTheme["THEME_IMMERSIVE_BLACK"] = "4";
    EnumWebViewTheme["THEME_DEFAULT"] = "0";
})(EnumWebViewTheme || (EnumWebViewTheme = {}));
/**
 * 动态设置 WebView 主题
 * @since Yoli >= 0.8.0
 */
export var setTheme = function (args) {
    return invoke('biz.setTheme', args);
};

export var rateUs = function () {
  return invoke('biz.rateUs');
};
/**
 * 个人中心领取积分后动画
 * @since Yoli >= 0.9.2
 */
export var getAwardToast = function (args) {
    return invoke('biz.getAwardToast', args);
};
/**
 * 完成任务通知客户端
 * @since Yoli >= 0.9.2
 */
export var finishMission = function (args) {
    return invoke('biz.broadcast.finishMission', args);
};

export var callLoginPanel = function() {
    return invoke('biz.openLoginView')
}

export var callMainPanel = function() {
    return invoke('biz.openMainView')
}

export var getUserToken = function() {
    return invoke('biz.accountModel')
}