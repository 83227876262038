import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import crypto from 'crypto';
import EXIF from 'exif-js';

export function formatDate(value:Date, template:string) {
  const fullYear = value.getFullYear().toString()
  const month = (value.getMonth() + 1).toString()
  const fullMonth = +month < 10 ? '0' + month : month.toString()
  const date = value.getDate().toString()
  const fullDate = +date < 10 ? '0' + date : date.toString()
  const hour = value.getHours().toString()
  const fullHour = +hour < 10 ? '0' + hour : hour.toString()
  const minute = value.getMinutes().toString()
  const fullMinute = +minute < 10 ? '0' + minute : minute.toString()
  const second = value.getSeconds().toString()
  const fullSecond = +second < 10 ? '0' + second : second.toString()
  
  return template
    .replace('yyyy', fullYear)
    .replace('mm', fullMonth)
    .replace('dd', fullDate)
    .replace('hh', fullHour)
    .replace('ii', fullMinute)
    .replace('ss', fullSecond)
    .replace('m', month)
}

export function formatDuration(value:number) {
  const hour = ~~(value / 60 / 60)
  const minute = ~~((value - hour * 60 * 60) / 60)
  const second = value - hour * 60 * 60 - minute * 60
  let result = `${second}s`
  if (minute) result = `${minute}m ` + result
  if (hour) result = `${hour}h ` + result
  return result
}

export function getCurrentMonth() {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const fixedMonth = month < 10 ? '0' + month : month.toString()
  return `${year}-${fixedMonth}`
}

export function dataURLtoBlob(dataurl:string) {
  const arr = dataurl.split(',');
  const mime = (arr[0] ? arr[0].match(/:(.*?);/) || [] : [])[1];
  let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
  u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function md5(text:string) {
  const md5sum = crypto.createHash('md5');
  md5sum.update(text)
  return md5sum.digest('hex')
}

export type IWrapPromiseResult = {read:() => any}
export type IWrapPromise = (promise:Promise<any>) => IWrapPromiseResult
export const wrapPromise:IWrapPromise = (promise) => {
  let status = "pending";
  let result:any;
  let suspender = promise.then(
    (r:any) => {
      status = "success";
      result = r;
    },
    (e:Error) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}

export function getUrlQueries(url:string) {
  const search = url.split('?').pop()
  return search
    ? search.split('&').reduce<{[key:string]:string}>((result, str) => {
      const [ key, value ] = str.split('=')
      result[key] = value
      return result
    }, {})
    : {}
}

const defaultValidateRules = [
  {
    key: 'nickname',
    validate: (value:string) => {
      const length = value.trim().length
      return length >= 4 && length <= 15
    },
    messageKey: 'message.error.nicknameInvalid'
  },
  {
    key: 'birthday',
    validate: (value:string) => !!value,
    messageKey: 'message.error.birthdayEmpty'
  },
  {
    key: 'birthday',
    validate: (value:string) => (new Date().valueOf() - new Date(value).valueOf()) / 1000 / 60 / 60 / 24 / 365 > 18,
    messageKey: 'message.error.ageNotEnough'
  },
  {
    key: 'birthday',
    validate: (value:string) => (new Date().valueOf() - new Date(value).valueOf()) / 1000 / 60 / 60 / 24 / 365 <= 100,
    messageKey: 'message.error.ageTooLarge'
  }
]

export function validateForm(data:any, rules:any = null) {
  let succ = true
  let messageKey = ''
  let field = ''
  const validateRules = rules || defaultValidateRules
  for(const { key, validate, messageKey: mk } of validateRules) {
    const validateResult = validate(data[key])
    if (!validateResult) {
      succ = false
      messageKey = mk
      field = key
      break
    }
  }
  return { succ, field, messageKey }
}

const imageCompressSize = Number(process.env.REACT_APP_UPLOAD_IMAGE_SIZE) || 500 * 1024

function isIphoneX() {
  // iPhone X、iPhone XS
  const isIPhoneX = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 375 && window.screen.height === 812;
  // iPhone XS Max
  const isIPhoneXSMax = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 414 && window.screen.height === 896;
  // iPhone XR
  const isIPhoneXR = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 2 && window.screen.width === 414 && window.screen.height === 896;

  return isIPhoneX || isIPhoneXSMax || isIPhoneXR || false
}

function imageToDataUrl(image:HTMLImageElement, targetSize:number = imageCompressSize) {
  return new Promise<string>((resolve, reject) => {
    const canvas:HTMLCanvasElement|null = document.querySelector('#Canvas')
    if (!canvas) return reject(Error('Canvas is missing.'))
  
    const ctx = canvas.getContext('2d');
    if (!ctx) return reject(Error('Context create failed.'))
  
    const size = image.src.length
    
    EXIF.getData(image, () => {
      const orientation = EXIF.getTag(image, 'Orientation')
      const width = image.width
      const height = image.height
      canvas.width = width
      canvas.height = height
      // if ([6,8].indexOf(orientation) && width < height) {
      //   ctx.drawImage(image, 0, 0, width, height);
      // }
      if (isIphoneX()) {
        ctx.drawImage(image, 0, 0, width, height);
      }
      else switch (orientation) {
        case 6:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(Math.PI / 2);
          ctx.drawImage(image, 0, -height, width, height);
          break;
        case 3:
          ctx.rotate(Math.PI);
          ctx.drawImage(image, -width, -height, width, height);
          break;
        case 8:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(3 * Math.PI / 2);
          ctx.drawImage(image, -width, 0, width, height);
          break;
        default:
          ctx.drawImage(image, 0, 0, width, height);
      }
      const dataUrl = canvas.toDataURL('image/jpeg', Math.min(1, targetSize / size))
      resolve(dataUrl)
    })
  }).catch(error => {
    // SystemLog.error(error.message)
    return ''
  })
}

export function dataURItoBlob(dataUrl:string) {
  const arr = dataUrl.split(',')
  const mime = ((arr[0] || '').match(/:(.*?);/) || [])[1]
  const bstr = atob(arr[1])
  let n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

export function compressImage(file:File, size:number = imageCompressSize) {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload=function () {
      let content = reader.result as string; //图片的src，base64格式
      let img = new Image();
      img.src = content || '';
      img.onload= async function () { //图片加载完毕
        const dataUrl = await imageToDataUrl(img, size)
        resolve(dataUrl)
      }
    };
  }).catch(error => {
    console.log('[H5 Compress] Failed! Message: ' + error.message)
    return ''
  })
  
}

type RuleItem = {
  field:string
  validator:(value:any) => string
}

export function validateProfileForm(formData:{[key:string]:any}, rules:RuleItem[]) {
  for (const rule of rules) {
    const { field, validator } = rule
    if (!formData[field]) continue

    const value = formData[field]
    const msg = validator(value)
    if (msg) return msg 
  }
  return ''
}

export const formRules:RuleItem[] = [{
  field: 'nickname',
  validator: (nickname:string) => {
    return !(/^[A-Za-z][A-Za-z0-9\s]{1,28}[A-Za-z0-9]$/.test(nickname))
      ? 'message.error.nicknameInvalid'
      : ''
  }
}, {
  field: 'birthday',
  validator: (birthday:string) => {
    return !birthday
      ? 'message.error.birthdayEmpty'
      : new Date(birthday).valueOf() > Date.now() - 18 * 365 * 24 * 60 * 60 * 1000
        ? 'message.error.ageNotEnough'
        : new Date(birthday).valueOf() < Date.now() - 100 * 365 * 24 * 60 * 60 * 1000
          ? 'message.error.ageTooLarge'
          : ''
  }
}, {
  field: 'language',
  validator: (language:string) => {
    return !language
      ? 'message.error.languageIsRequired'
      : ''
  }
}]

export function render(App:() => JSX.Element) {
  ReactDOM.render(
    <Suspense fallback={<div />}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>,
    document.getElementById('root')
  );
}

export function getQuery() {
  const search = window.location.search.slice(1)
  if (!search) return {}

  const query:any = {}
  search.split('&').forEach(str => {
    const [ key, value ] = str.split('=')
    query[key] = value
  })

  return query
}

let timeCache:any = {}

export function timeReduce(key:string) {
  const latestTime = timeCache[key] || (timeCache[key] = Date.now())
  return Date.now() - latestTime
}