import React, { useState } from 'react';
import './index.sass';

type SwitchProps = {
  defaultValue:boolean
  onChange?:any
}

export default function Switch(props:SwitchProps) {
  const { defaultValue, onChange } = props
  const [ isOpen, setOpen ] = useState(() => defaultValue)

  function handleSwitch() {
    const value = !isOpen
    setOpen(value)
    onChange && onChange(value)
  }
  
  return <div className="cmp cmp-switch">
    <div
      className={`main ${isOpen ? 'open' : 'close'}`}
      onTouchStart={handleSwitch} />
  </div>
}