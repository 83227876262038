import React from 'react';
import './index.sass';

interface IIconButton {
  icon:any
  onClick:any
}

export function IconButton(props:IIconButton) {
  const { icon, onClick } = props

  return <div className="cmp cmp-icon-button" onTouchStart={onClick}>
    {icon}
  </div>
}

interface IButton {
  enable?:boolean
  text?:string
  type?:'primary'|'normal'|'transparent'|'red'
  onClick:any
  icon?:JSX.Element
  iconDirect?:'up'|'bottom'|'left'|'right'
}

export function Button(props:IButton) {
  const { text, icon, iconDirect = 'up', onClick, type, enable = true } = props
  return <div className={`cmp cmp-button ${type ? type : ''} ${enable ? '' : 'disabled'} direct-${iconDirect}`} onTouchStart={enable ? onClick : () => {}}>
    {icon}
    {text ? <label>{text}</label> : <></>}
  </div>
}

export interface ButtonsProps {
  buttons:JSX.Element[]
  direct?:'start'|'end'
}

export default function Buttons(props:ButtonsProps) {
  const { buttons, direct = 'start' } = props
  return <div className={`cmp cmp-buttons direct-${direct}`}>
    {buttons}
  </div>
}