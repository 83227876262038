


const Color = {
  Red: process.env.REACT_APP_COLOR_RED || '',
  Blue: process.env.REACT_APP_COLOR_BLUE || '',
  Primary: process.env.REACT_APP_COLOR_PRIMARY || '',
  White: '#ffffff',
  Body: '#f0eef2',
  Pink: '#F8F5FA'
}

export default Color