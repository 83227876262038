import React from 'react';
import './index.sass';

type HeaderSideProps = {
  buttons?:JSX.Element
  texts?:any[]
  pos?:'left'|'right'
}

function HeaderSide(props:HeaderSideProps) {
  const { texts, buttons, pos = 'right' } = props

  return <div className={`header-side ${pos}`}>
    {buttons || ''}
    {texts || ''}
  </div>
}

type HeaderPros = {
  left?:HeaderSideProps
  right?:HeaderSideProps
  title?:any
}

export default function Header(props:HeaderPros) {
  const { left, right, title } = props
  return <div className="cmp cmp-header">
    {left ? <HeaderSide {...left} pos="left" /> : ''}
    {title ? <div className="middle">
      {typeof title === 'string' ? <h1>{title}</h1> : title }
    </div> : ''}
    {right ? <HeaderSide {...right} pos="right" /> : ''}
  </div>
}