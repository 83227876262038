import { invoke, createEvents } from '../../base';

export var joinChannel = function (args) {
  // var { token, channelId } = args
  // var wrapArgs = {
  //   token: token,
  //   channelId: channelId
  // }
  return invoke('livchat.joinChannel', args);
}

export var openChatSession = function (args) {
  var { userId, nickname, avatarUrl } = args
  var wrapArgs = {
    userId,
    nickname,
    avatarUrl
  }
  return invoke('livchat.openChatSession', wrapArgs);
}

export var getCurrentAccountInfo = function () {
  return invoke('livchat.getAccountInfo');
}

export var saveAccountInfo = function (args) {
  var { token, channelId } = args
  var wrapArgs = {
    token: token,
    channelId: channelId
  }
  return invoke('livchat.saveAccountInfo', wrapArgs);
}

export var tryFetchAccount = function () {
  return invoke('livchat.tryFetchAccount');
}

export var logout = function() {
  invoke('livchat.logout')
}

export var getServerHost = function() {
  return invoke('livchat.getServerHost')
}

export var callUser = function(args) {
  return invoke('livchat.callUser', args)
}

export var getUserInfo = function(args) {
  var { userId, forceUpdate, onUserInfo } = args
  var wrapArgs = {
    userId,
    forceUpdate,
    events: createEvents({ onUserInfo: onUserInfo })
  }
  return invoke('livchat.getUserInfo', wrapArgs)
}

export var startMatch = function() {
  return invoke('livchat.startMatch')
}

export var notifyEvent = function(event) {
  return invoke('livchat.notifyEvent', { event })
}

export var tokenInvalid = function() {
  return invoke('livchat.tokenInvalid')
}

export var getCommonRequestHeader = function() {
  return invoke('livchat.getCommonRequestHeader')
}

export var uploadS3Image = function(args) {
  const { onResponse, onError, onFileChoosen } = args
  const events = createEvents({ onResponse, onError, onFileChoosen })
  return invoke('livchat.uploadS3Image', { events })
}

export var getCoins = function() {
  return invoke('livchat.getCoins')
}

export var getRegion = function() {
  return invoke('livchat.getRegion')
}

export var playVideo = function(args) {
  return invoke('livchat.playVideo', args)
}

export var getVersionInfo = function() {
  return invoke('livchat.getVersionInfo')
}

export var goToGp = function() {
  return invoke('livchat.goToGp')
}

export var getSubscribe = function() {
  return invoke('livchat.getSubscribe')
}

export var openAnchorDetail = function(args) {
  return invoke('livchat.openAnchorDetail', args)
}

export var openVipLevel = function() {
  return invoke('livchat.openVipLevel')
}

export var tppPayCallBack = function() {
  return invoke('livchat.tppPayCallBack')
}