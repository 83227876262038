import { invoke, createEvents } from '../../base';
import * as UI from './ui'

export var liveMemory = function (args) {
  // var wrapArgs = {}
  // return invoke('', wrapArgs)
  return UI.toast({
    message: args.title,
    duration: 3,
  })
};


export var netRequestUploadFile = function (args) {
  var wrapArgs = makeRequest(args)
  return invoke('network.request.uploadfile', wrapArgs);
}

export var netRequestGet = function (args) {
  var wrapArgs = makeRequest(args)
  return invoke('network.request.get', wrapArgs);
}

export var netRequestPost = function (args) {
  var wrapArgs = makeRequest(args)
  return invoke('network.request.post', wrapArgs);
}

export var makeRequest = function (args) {
  if (args !== undefined) {
    var { host, onResponse, onError, onFileChoosen, headers, params, body, ext, bodyType, files } = args
    var wrapArgs = {
      host: host,
      headers: headers,
      params: params,
      body: body,
      ext: ext,
      bodyType: bodyType,
      files: files,
      events: createEvents({ onResponse: onResponse, onError: onError, onFileChoosen: onFileChoosen }),
    };
    return wrapArgs
  } else {
    return undefined
  }
}

export var registerGlobalModel = function (args) {
  var { id, observerId, isPresistence, defaultData, notifyChanged } = args
  var request = makeRequest(args.request)
  var wrapArgs = {
    id: id,
    observerId: observerId,
    isPresistence: isPresistence,
    defaultData: defaultData,
    request: request,
    // pushRequest: makeRequest(args.pushRequest),
    events: createEvents({ notifyChanged: notifyChanged })
  }
  return invoke('globalmodel.register', wrapArgs)
}

export var updateGlobalModel = function (args) {
  var { id, observerId, updateData } = args
  var wrapArgs = {
    id: id,
    observerId: observerId,
    updateData: updateData
  }
  return invoke('globalmodel.update', wrapArgs)
}

export var unregisterGlobalModel = function (args) {
  var { id, observerId } = args
  var wrapArgs = {
    id: id,
    observerId: observerId
  }
  return invoke('globalmodel.unregister', wrapArgs)
}

export var fetchGlobalModel = function (args) {
  return invoke('globalmodel.fetch', { id: args.id })
}

export var removeGlobalModel = function (args) {
  return invoke('globalmodel.remove', { id: args.id })
}

export var pushGlobalModel = function (args) {
  return invoke('globalmodel.push', { id: args.id, linkageIds: args.linkageIds })
}