import * as JSBridge from 'libs/jsbridge'

export const LogTypes = {
  JSBridge: 'JSBridge',
  Server: 'Server',
  Web: 'Web'
} 

function isWebDebug(logType:string) {
  switch(logType) {
    default:
      return true
    case LogTypes.JSBridge:
      return process.env.REACT_APP_DEBUG_JSBRIDGE === '1'
  }
}

export function log (logType:string, ...args:any[]) {
  isWebDebug(logType) && console.log(`[${logType}]`, ...args)
  JSBridge.localLog({
    message: `[${logType}] ${args.length === 1 && typeof args[0] === 'string' ? args[0] : JSON.stringify(args)}`
  }).catch(error => {
    isWebDebug(logType) && console.error(`[${LogTypes.JSBridge}]`, error.message, 'localLog')
  })
}

export function info (logType:string, ...args:any[]) {
  isWebDebug(logType) && console.info(`[${logType}]`, ...args)
  JSBridge.localLog({
    message: `[${logType}] ${args.length === 1 && typeof args[0] === 'string' ? args[0] : JSON.stringify(args)}`
  }).catch(error => {
    isWebDebug(logType) && console.error(`[${LogTypes.JSBridge}]`, error.message, 'localLog')
  })
}

export function error (logType:string, ...args:any[]) {
  isWebDebug(logType) && console.error(`[${logType}]`, ...args)
  JSBridge.localLog({
    message: `[${logType}] ${args.length === 1 && typeof args[0] === 'string' ? args[0] : JSON.stringify(args)}`
  }).catch(error => {
    isWebDebug(logType) && console.error(`[${LogTypes.JSBridge}]`, error.message, 'localLog')
  })
}

export function warn (logType:string, ...args:any[]) {
  isWebDebug(logType) && console.warn(`[${logType}]`, ...args)
  JSBridge.localLog({
    message: `[${logType}] ${args.length === 1 && typeof args[0] === 'string' ? args[0] : JSON.stringify(args)}`
  }).catch(error => {
    isWebDebug(logType) && console.error(`[${LogTypes.JSBridge}]`, error.message, 'localLog')
  })
}
