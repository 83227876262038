import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enTranslation from 'locales/en/translation.json'
import zhTranslation from 'locales/zh/translation.json'

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    // fallbackLng: 'en',
    debug: true,

    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.addResourceBundle('en', 'translations', enTranslation, true, true)
i18n.addResourceBundle('zh', 'translations', zhTranslation, true, true)
  

export default i18n;