import { invoke, createEvents } from '../../base';

export var exitApp = function () {
  return invoke('device.exitApp');
};

export var setAccountId = function (data) {
  return invoke('device.setAccountId', { data: data.data })
}

export var setMobile = function (data) {
  return invoke('device.setMobile', { data: data.data })
}

export var setChannelId = function (data) {
  return invoke('device.setChannelId', { data: data.data })
}

export var setUtmSource = function (data) {
  return invoke('device.setUtmSource', { data: data.data })
}

export var callSelectContact = function (args) {
  var { onResponse } = args
  var wrapArgs = {
    events: createEvents({ onResponse: onResponse })
  }
  return invoke('device.selectContact', wrapArgs)
}

/**
 * 获取当前网络类型
 */
export var getNetworkType = function () {
  return invoke('device.getNetworkType');
};
var StorageType;
(function (StorageType) {
  StorageType["local"] = "local";
  StorageType["session"] = "session";
})(StorageType || (StorageType = {}));
/**
 * 封装 Storage
 * @param type 存储类型
 */
function genStorage(type) {
  return {
    setItem: function (keyName, keyValue) {
      var args = {
        type: type,
        name: keyName,
        value: keyValue,
      };
      return invoke('device.storage.setItem', args);
    },
    getItem: function (keyName) {
      return invoke('device.storage.getItem', { type: type, name: keyName })
        .then(function (result) {
          return Promise.resolve(result.value);
        })
        .catch(function (err) {
          return Promise.reject(err);
        });
    },
    removeItem: function (keyName) {
      return invoke('device.storage.removeItem', { type: type, name: keyName });
    },
  };
}
/**
 * 本地永久存储
 */
export var localStorage = genStorage(StorageType.local);
/**
 * Session 级别存储
 */
export var sessionStorage = genStorage(StorageType.session);
/**
 * 设置、获取客户端设置项
 */
export var settings = {
  setItems: function (keyValuePair) {
    return invoke('device.settings.setItems', { keyValuePair: keyValuePair });
  },
  getItems: function (keyNameList) {
    return invoke('device.settings.getItems', { keyNameList: keyNameList });
  },
};
/**
 * 获取屏幕属性
 * @since Yoli >= 0.7.3
 */
export var getScreenAttributes = function () {
  return invoke('device.getScreenAttributes');
};
/**
 * 设置、获取用户的剪贴板
 * @since Yoli >= 0.8.0
 */
export var clipboard = {
  put: function (value) {
    return invoke('device.clipboard.put', { value: value });
  },
  get: function () {
    return invoke('device.clipboard.get');
  }
};

export function getImage(args) {
  var { imageUrl, onImageLoaded } = args
  var wrapArgs = {
    imageUrl: imageUrl,
    events: createEvents({ onImageLoaded: onImageLoaded })
  }
  return invoke('device.getImage', wrapArgs)
}

export function showLoadingCover(args) {
  return invoke('device.showLoadingCover', args)
}

export function hideLoadingCover() {
  return invoke('device.hideLoadingCover')
}

export function keepAlive() {
  return invoke('device.keepAlive')
}