import React from 'react';
import * as JSBridge from 'libs/jsbridge';
import * as SystemLog from 'libs/log';
import { IUser } from 'store/user';
import { dataURLtoBlob } from 'libs/helper';
import { IMedia } from '_';

type LinkProps = {
  to:string
  className?:string
  children:any
  onClick?:any
}

type INativeImage = {
  img:string
  url:string
}

const cacheImageUrls:any = {}

export function useWebView() {
  function goBack() {
    JSBridge.popWindow().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
      window.history.back()
    })
  }

  function goForward(to:string) {
    const url = `${window.location.protocol}//${window.location.host}${to}`
    JSBridge.pushWindow(url).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
      window.location.href = to
    })
  }

  function Link(props:LinkProps) {
    const { children, to, className, onClick } = props
    return <div className={`cmp cmp-history-link ${className || ''}`} onClick={() => {
      onClick && onClick()
      goForward(to)
    }}>{children}</div>
  }

  function openChatSession(anchor:IUser) {
    JSBridge.openChatSession({
      userId: anchor.userId,
      nickname: anchor.nickname,
      avatarUrl: anchor.avatarThumbUrl
    }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function logout() {
    try {
      JSBridge.logout()
    } catch(error) {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    }
  }

  function getServerHost() {
    return JSBridge.getServerHost().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
      return process.env.REACT_APP_API_HOST
    })
  }

  function callUser(toUserId:string) {
    return JSBridge.callUser({ toUserId }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function getImage(imageUrl:string, localCache?:number) {
    const cacheKey = `cache:getImage:${imageUrl}`
    if (localCache === 1) {
      const cacheData = localStorage.getItem(cacheKey)
    
      if (localCache && cacheData) {
        const blob = dataURLtoBlob(cacheData)
        const blobUrl = URL.createObjectURL(blob)
        return Promise.resolve(blobUrl)
      }
    } else if (localCache === 2) {
      const cacheData = cacheImageUrls[cacheKey]
      if (cacheData) return Promise.resolve(cacheData)
    }
    return new Promise<string>(resolve => JSBridge.getImage({ 
      imageUrl: imageUrl,
      onImageLoaded: (data:string) => {
        const result = (typeof data === 'string' ? JSON.parse(data) : data) as INativeImage
        const { img } = result
        SystemLog.info(SystemLog.LogTypes.JSBridge, 'getImage', result)
        if (!img) {
          // SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
          return resolve('')
        }
        if (localCache === 1) {
          localStorage.setItem(cacheKey, img)
        }
        const blob = dataURLtoBlob(img)
        const blobUrl = URL.createObjectURL(blob)
        if (localCache === 2) {
          cacheImageUrls[cacheKey] = blobUrl
        }
        resolve(blobUrl)
      }
    }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
      resolve('')
    }))
  }

  function getImageMedia(media:IMedia) {
    return new Promise<string>(resolve => JSBridge.getImage({ 
      imageUrl: media.mediaUrl,
      onImageLoaded: (data:string) => {
        const result = (typeof data === 'string' ? JSON.parse(data) : data) as INativeImage
        const { img } = result
        SystemLog.info(SystemLog.LogTypes.JSBridge, 'getImage', result)
        if (!img) {
          // SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
          return resolve('')
        }
        const blob = dataURLtoBlob(img)
        const blobUrl = URL.createObjectURL(blob)
        resolve(blobUrl)
      }
    }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
      resolve('')
    }))
  }

  function startMatch() {
    JSBridge.startMatch().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function fetchAccount() {
    JSBridge.tryFetchAccount().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function on(eventName:string, callback:any) {
    try {
      JSBridge.on(eventName, callback)
    } catch(error) {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    }
  }

  function showModel(message:string) {
    JSBridge.showLoadingCover({ message }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function hideModel() {
    JSBridge.hideLoadingCover().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function keepAlive() {
    JSBridge.keepAlive().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function popWindow() {
    JSBridge.popWindow().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function getCommonParams() {
    return JSBridge.getCommonRequestHeader().then((params:any) => {
      return typeof params === 'string' ? JSON.parse(params) : params
    }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function getCoins() {
    return JSBridge.getCoins().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function getUserInfo(userId:string, forceUpdate:boolean) {
    return new Promise<string>(resolve => JSBridge.getUserInfo({
        userId,
        forceUpdate,
        onUserInfo: (data:string) => {
          SystemLog.info(SystemLog.LogTypes.JSBridge, data)
          resolve(data)
        }
      }).catch(error => {
        SystemLog.info(SystemLog.LogTypes.JSBridge, error.message)
        resolve('')
      })
    )
  }

  function getRegion() {
    return JSBridge.getRegion().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function playVideo(videoPath:string) {
    return JSBridge.playVideo({ videoPath }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function getVersionInfo() {
    return JSBridge.getVersionInfo().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function gotoGp() {
    return JSBridge.goToGp().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function getSubscribe() {
    return JSBridge.getSubscribe().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function openAnchorDetail(userId:string) {
    return JSBridge.openAnchorDetail({ userId }).catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  function openVipLevel() {
    return JSBridge.openVipLevel().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }

  return {
    on,
    goBack,
    goForward,
    Link,
    logout,
    callUser,
    getImage,
    startMatch,
    fetchAccount,
    getServerHost,
    openChatSession,
    openAnchorDetail,
    showModel,
    hideModel,
    keepAlive,
    popWindow,
    getCoins,
    getRegion,
    getUserInfo,
    playVideo,
    gotoGp,
    getImageMedia,
    getSubscribe,
    getVersionInfo,
    getCommonParams,
    openVipLevel
  }
}
