import React from 'react';
import './index.sass';

type IconProps = {
  name:string
  onTouch?:any
  display?:boolean
}

export default function Icon(props:IconProps) {
  const { name, onTouch, display = true } = props
  return <span
    className={`icon icon-user icon-${name} ${display ? '' : 'hide'}`}
    onClick={onTouch} />
}