Object.defineProperty(exports, "__esModule", { value: true });
var Consumer = /** @class */ (function () {
    function Consumer() {
        this.callbackCollection = {};
        this.eventCollection = {};
    }
    Consumer.prototype.addCallback = function (name, fn) {
        this.callbackCollection[name] = fn;
    };
    Consumer.prototype.applyCallback = function (name, res) {
        var fn = this.callbackCollection[name];
        this.callbackCollection[name] = undefined;
        fn(res);
    };
    Consumer.prototype.on = function (eventName, listener) {
        var eventList = this.getEventList(eventName);
        eventList.push(listener);
    };
    Consumer.prototype.emit = function (eventName, response) {
        var eventList = this.getEventList(eventName);
        eventList.forEach(function (listener) {
            listener(response);
        });
    };
    Consumer.prototype.off = function (eventName, listener) {
        var eventList = this.getEventList(eventName);
        for (var index = 0; index < eventList.length; index += 1) {
            var item = eventList[index];
            if (listener === item) {
                eventList.splice(index, 1);
                index -= 1;
            }
        }
    };
    Consumer.prototype.getEventList = function (eventName) {
        if (!this.eventCollection[eventName]) {
            this.eventCollection[eventName] = [];
        }
        return this.eventCollection[eventName];
    };
    return Consumer;
}());
exports.Consumer = Consumer;
