Object.defineProperty(exports, "__esModule", { value: true });
/**
 * 生成全局唯一id
 */
exports.uuid = function () {
    var time = new Date().getTime();
    var random = Math.floor(Math.random() * Math.floor(10000));
    return "" + time + random;
};
exports.getRootScope = function () {
    return typeof global !== 'undefined' ? global : window;
};
