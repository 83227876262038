import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'component/color';
import Header from 'component/header';
import List from 'component/list';
import Icon from 'component/icon/user';
import Buttons, { IconButton } from 'component/buttons';
import { useWebView } from 'component/webview';
import { useUserInfo } from 'store/user';
import { render } from 'libs/helper';
import 'base.multi.tsx';
import './index.sass';

export default function About() {
  const { t } = useTranslation()
  const [ version, setVersion ] = useState<string>('')
  const { fetchUserInfo } = useUserInfo()
  const [ userId, setUserId ] = useState<string>('')
  const { goBack, getVersionInfo, gotoGp, on } = useWebView()
  const [ latestVersion, setLatestVersion ] = useState<string>('')
  const [ hasNewVersion, setHasNewVersion ] = useState<boolean>(false)
  const refNewVersion = useRef<string>('')
  refNewVersion.current = latestVersion
  
  useEffect(() => {
    document.body.style.backgroundColor = Color.Body
    fetchUserInfo(false).then((userInfo:any) => {
      if (!userInfo || !userInfo.userId) return

      const { userId } = userInfo
      const str = userId.substr(0, 8) + '.' + userId.substr(userId.length - 4, 4)
      setUserId(str)
    })

    getVersionInfo()
    on('getVersionInfo', (data:any) => {
      if (!data) return

      const { curVer, newVer } = data
      const isNewVersion = curVer !== newVer
      setVersion(curVer)
      setLatestVersion(newVer || curVer)
      
      const isClicked = !!localStorage.getItem(`view:about:home:version:${newVer}`)
      if (isNewVersion && !isClicked) {
        setHasNewVersion(true)
      }
    })
    function handleVisibilityChange() {
      if (document.hidden) return
      // 因为之前已经有一个监听了，再监听就重复了
      // 之所以不得不做visibilitychange，是因为再ios下H5在不可见情况下可以正常接收信息，但android不行
      const isClicked = !!localStorage.getItem(`view:about:home:version:${refNewVersion.current}`)
      if (isClicked) setHasNewVersion(false)
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }
  }, [])

  function handleLatestVersionClick() {
    if (refNewVersion.current) {
      localStorage.setItem(`view:about:home:version:${refNewVersion.current}`, '1')
    }
    gotoGp()
  }

  return <div className="pg pg-about">
    <Header 
      left={{
        buttons: <Buttons buttons={[
          <IconButton icon={<Icon name="nav-back" />} onClick={() => goBack()}/>
        ]} />
      }}
      title={t('about.home.title')}
    />
    <div className="body">
      <div className="logo-pane">
        <img src={`/images/logo-${process.env.REACT_APP_NAME}.png`} />
        <span className="version">{version}</span>
        <span className="user-id">{userId}</span>
      </div>
      <List
        list={[
          {type: 'webview-link', icon: <Icon name="link-green"/>, name: t('about.home.termsAndConditions'), href: "/about/condition"},
          {type: 'webview-link', icon: <Icon name="link-orange" />, name: t('about.home.privacyPolicy'), href: "/about/policy"},
          {type: 'native-link', icon: <Icon name="info" />, name: t('about.home.latestVersion'), onClick: handleLatestVersionClick, value: refNewVersion.current, point: hasNewVersion ? 'alert' : ''},
          {type: 'native-link', icon: <Icon name="about-us" />, name: t('about.home.rateUs'), onClick: gotoGp}
        ]} />
    </div>
  </div>
}

render(About)
