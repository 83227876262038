import { invoke } from '../../base';

export var localLog = function (args) {
  var { message, type, body } = args
  var wrapArgs = {
    message: message,
    type: type,
    body: body
  }
  return invoke('log.localLog', wrapArgs);
}

export var pvLogMotion = function (args) {
  var { x, type, y } = args
  var wrapArgs = {
    x: x,
    type: type,
    y: y
  }
  return invoke('log.pvlog.motion', wrapArgs);
}

export var setExtPageName = function (data) {
  return invoke('log.pvlog.pagename', { data: data.data })
}

export var serverLog = function(args) {
  var { message, type, body } = args
  var wrapArgs = {
    message: message,
    type: type,
    body: body
  }
  return invoke('log.serverLog', wrapArgs);
}

export var performanceLog = function(args) {
  var { message, type, body } = args
  var wrapArgs = {
    message: message,
    type: type,
    body: body
  }
  return invoke('log.performanceLog', wrapArgs);
}