import { invoke } from '../../base';
/**
 * 打开native页面
 */
export var openNativePage = function (args) {
    return invoke('navigation.openNativePage', args);
};
/**
 * 新窗口打开URL
 */
export var pushWindow = function (url) {
    return invoke('navigation.pushWindow', { url: url });
};
/**
 * 关闭当前 WebView
 */
export var popWindow = function () {
    return invoke('navigation.popWindow');
};
/**
 * 后退
 * 若当前 WebView 有 history，则后退，否则关闭当前 WebView
 */
export var back = function () {
    return invoke('navigation.back');
};
/**
 * 设置导航栏标题
 */
export var setTitle = function (title) {
    return invoke('navigation.setTitle', { title: title });
};
/**
 * 刷新当前 WebView
 */
export var refresh = function () {
    return invoke('navigation.refresh');
};
/**
 * 调用客户端 intent 路由
 * since Yoli >= 0.9.0
 */
export var urlRouter = function (args) {
    return invoke('navigation.urlRouter', args);
};
