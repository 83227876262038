/**
 * 生成全局唯一id
 */
export var uuid = function () {
    var time = new Date().getTime();
    var random = Math.floor(Math.random() * Math.floor(10000));
    return "" + time + random;
};
/**
 * 获取当前js执行环境root对象
 */
export var getRootScope = function () {
    /* istanbul ignore next */
    return typeof global !== 'undefined' ? global : window;
};
