
import * as JSBridge from 'libs/jsbridge';
import * as SystemLog from 'libs/log';

let host = ''
const hostPromise = JSBridge.getServerHost()
.catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return process.env.REACT_APP_API_HOST
})
.then(remoteHost => host = remoteHost)
function getHost() {
  return host
    ? Promise.resolve(host)
    : hostPromise
}

const defaultHeaders = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json;charset=UTF-8'
}
const invalidTokenCodeList = [ '10010301', '10010302', '100103', '10010303', '10010304' ]
const checkInvalidToken = (response) => {
  const { code } = response || {}
  if (invalidTokenCodeList.indexOf(code.toString()) > -1) {
    JSBridge.tokenInvalid().catch(error => {
      SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
    })
  }
}


/**
 * path: /user/activeing
 * method: post 
 * tags: user-controller
 * summary: 用户签到
 * operationId: activeingUsingPOST
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const postActiveing = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/activeing',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/addFriend
 * method: post 
 * tags: user-controller
 * summary: 添加好友
 * operationId: addFriendUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - followUserId | integer<int64> | 关注的好友id | 
     - requestNo | string<> |  | 
 * responses: 
   - 200 | OK
 */
export const postAddFriend = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/addFriend',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/avatar/search
 * method: post 
 * tags: user-controller
 * summary: 查询用户头像图片
 * operationId: searchAvatarUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
 * responses: 
   - 200 | OK
 */
export const postAvatarSearch = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/avatar/search',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/dingTalkRobot
 * method: post 
 * tags: user-controller
 * summary: 发送钉钉消息通知
 * operationId: dingTalkRobotUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - content | string<> | 消息内容 | 
 * responses: 
   - 200 | OK
 */
export const postDingTalkRobot = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/dingTalkRobot',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/feedback
 * method: post 
 * tags: user-controller
 * summary: feedback
 * operationId: feedbackUsingPOST
 * consumes: application/json
 * parameters: 
   - feedBackReqs | body | feedBackReqs | true | undefined 
     - context | string<> | 反馈内容 | 
     - email | string<> | 邮件 | 
     - feedbackType | string<> | 反馈类型 | 
     - photoProof | array<> | 图片媒体信息 | 
 * responses: 
   - 200 | OK
 */
export const postFeedback = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/feedback',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/feedbackType
 * method: get 
 * tags: user-controller
 * summary: 反馈状态枚举
 * operationId: selectGuildWithdrawStatusUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getFeedbackType = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/feedbackType',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getAvatarList
 * method: get 
 * tags: user-controller
 * summary: 获取一批头像和名称
 * operationId: getAvatarListUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getGetAvatarList = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getAvatarList',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getBroadcasterCoins
 * method: get 
 * tags: user-controller
 * summary: 获取主播金币
 * operationId: getBroadcasterCoinsUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getGetBroadcasterCoins = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getBroadcasterCoins',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getBroadcasterExtraInfo
 * method: get 
 * tags: user-controller
 * summary: 获取主播额外信息
 * operationId: getBroadcasterExtraInfoUsingGET
 * consumes: application/json
 * parameters: 
   - userId | query | userId | true | integer 
 * responses: 
   - 200 | OK
 */
export const getGetBroadcasterExtraInfo = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getBroadcasterExtraInfo',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getBroadcasterStatistics
 * method: post 
 * tags: user-controller
 * summary: 获取主播统计
 * operationId: getBroadcasterStatisticsUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - beginTime | string<> | 开始时间 | 
     - endTime | string<> | 结束时间 | 
 * responses: 
   - 200 | OK
 */
export const postGetBroadcasterStatistics = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/getBroadcasterStatistics',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getFriendsList
 * method: get 
 * tags: user-controller
 * summary: 获取好友列表
 * operationId: getFriendsListUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getGetFriendsList = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getFriendsList',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getUserCoins
 * method: get 
 * tags: user-controller
 * summary: 获取用户金币
 * operationId: getUserCoinsUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getGetUserCoins = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getUserCoins',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getUserInfo
 * method: get 
 * tags: user-controller
 * summary: 获取用户所有信息
 * operationId: getUserInfoUsingGET
 * consumes: application/json
 * parameters: 
   - userId | query | userId | false | integer 
 * responses: 
   - 200 | OK
 */
export const getGetUserInfo = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getUserInfo',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getUserListOnlineStatus
 * method: get 
 * tags: user-controller
 * summary: 获取一批用户在线状态
 * operationId: getUserListOnlineStatusUsingGET
 * consumes: application/json
 * parameters: 
   - userIds | query | userIds | true | array 
 * responses: 
   - 200 | OK
 */
export const getGetUserListOnlineStatus = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getUserListOnlineStatus',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getUserOnlineStatus
 * method: get 
 * tags: user-controller
 * summary: 获取用户在线状态
 * operationId: getUserOnlineStatusUsingGET
 * consumes: application/json
 * parameters: 
   - userId | query | userId | true | integer 
 * responses: 
   - 200 | OK
 */
export const getGetUserOnlineStatus = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getUserOnlineStatus',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/getUsernameAndAvatar
 * method: get 
 * tags: user-controller
 * summary: 获取某个用户的头像和名称
 * operationId: getUsernameAndAvatarUsingGET
 * consumes: application/json
 * parameters: 
   - userId | query | userId | true | integer 
 * responses: 
   - 200 | OK
 */
export const getGetUsernameAndAvatar = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/getUsernameAndAvatar',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/giveUserGifts
 * method: post 
 * tags: user-controller
 * summary: 赠送礼物
 * operationId: giveUserGiftsUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - channelName | string<> | 通话频道 | 
     - giftCode | string<> | 礼物编号 | 
     - num | integer<int32> | 赠送数量 | 
     - recipientUserId | integer<int64> | 受赠用户ID | 
 * responses: 
   - 200 | OK
 */
export const postGiveUserGifts = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/giveUserGifts',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/languageSelect
 * method: get 
 * tags: user-controller
 * summary: 查询语种枚举
 * operationId: selectLanguageTypeUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getLanguageSelect = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/languageSelect',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/matchFriends
 * method: post 
 * tags: user-controller
 * summary: 匹配接口
 * operationId: matchUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - gender | integer<int32> | 性别 | 
     - powerSwitch | boolean<> | 是否需要机器人 | false
     - requestNo | string<> |  | 
 * responses: 
   - 200 | OK
 */
export const postMatchFriends = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/matchFriends',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/oss/policy
 * method: get 
 * tags: user-controller
 * summary: oss授权
 * operationId: ossPolicyUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getOssPolicy = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/oss/policy',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/rongcloud/token
 * method: get 
 * tags: user-controller
 * summary: 获取融云token
 * operationId: rongcloudTokenUsingGET
 * consumes: application/json
 * parameters: 

 * responses: 
   - 200 | OK
 */
export const getRongcloudToken = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/rongcloud/token',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/s3/uploadUrl
 * method: get 
 * tags: user-controller
 * summary: s3授权
 * operationId: ossPolicyUsingGET_1
 * consumes: application/json
 * parameters: 
   - filename | query | filename | true | string 
 * responses: 
   - 200 | OK
 */
export const getS3UploadUrl = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestGet({
  host: host + '/user/s3/uploadUrl',
  params: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/saveBasicInfo
 * method: post 
 * tags: user-controller
 * summary: 保存用户基本信息
 * operationId: saveBasicInfoUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - birthday | string<> | 出生日期 | 
     - country | string<> | 国家 | 
     - gender | integer<int32> | 性别(1:男性 2:女性) | 
     - nickname | string<> | 昵称 | 
 * responses: 
   - 200 | OK
 */
export const postSaveBasicInfo = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/saveBasicInfo',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/saveUserInfo
 * method: post 
 * tags: user-controller
 * summary: 保存用户所有信息
 * operationId: saveUserInfoUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - about | string<> | 个性签名 | 
     - birthday | string<> | 出生日期 | 
     - language | string<> | 语言 | 
     - nickname | string<> | 昵称 | 
 * responses: 
   - 200 | OK
 */
export const postSaveUserInfo = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/saveUserInfo',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/searchCoinFlow
 * method: post 
 * tags: user-controller
 * summary: 搜索金币流水
 * operationId: searchCoinFlowUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - beginTime | string<> | 开始时间 | 
     - endTime | string<> | 结束时间 | 
     - limit | integer<int32> | 每页大小 | 
     - page | integer<int32> | 页码 | 
 * responses: 
   - 200 | OK
 */
export const postSearchCoinFlow = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/searchCoinFlow',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/updateAnswer
 * method: post 
 * tags: user-controller
 * summary: 更新接电话状态
 * operationId: updateAnswerUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - isAnswer | boolean<> | 是否接听 | false
 * responses: 
   - 200 | OK
 */
export const postUpdateAnswer = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/updateAnswer',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/updateAvatar
 * method: post 
 * tags: user-controller
 * summary: 更新头像
 * operationId: updateAvatarUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - avatarPath | string<> |  | 
 * responses: 
   - 200 | OK
 */
export const postUpdateAvatar = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/updateAvatar',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/updateMedia
 * method: post 
 * tags: user-controller
 * summary: 用户更新media
 * operationId: updateMediaUsingPOST
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - actionType | integer<int32> | 新增=1,更新=2,删除=3 | 
     - deleteMediaId | integer<int64> | 要删除的mediaId | 
     - mediaPath | string<> | mediaPath | 
     - mediaType | string<> | mediaType | 
     - replaceMediaId | integer<int64> | 之前的MediaId | 
 * responses: 
   - 200 | OK
 */
export const postUpdateMedia = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/updateMedia',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})


/**
 * path: /user/wall/search
 * method: post 
 * tags: user-controller
 * summary: 搜索用户墙列表
 * operationId: searchWallUsingPOST_1
 * consumes: application/json
 * parameters: 
   - reqs | body | reqs | true | undefined 
     - isRemoteImageUrl | boolean<> | 是否返回远程图片地址 | false
     - limit | integer<int32> | 每页大小 | 
     - page | integer<int32> | 页码 | 
 * responses: 
   - 200 | OK
 */
export const postWallSearch = (data, headers) => new Promise((resolve, reject) => getHost().then(host => JSBridge.netRequestPost({
  host: host + '/user/wall/search',
  body: data,
  headers: { ...defaultHeaders, ...headers },
  onResponse: (...args) => {
    SystemLog.log(SystemLog.LogTypes.JSBridge, args)

    const [ response ] = args
    checkInvalidToken(response)
    resolve(response)
  },
  onError: (...args) => {
    SystemLog.error(SystemLog.LogTypes.JSBridge, args)

    const [ error ] = args
    reject(new Error('Unknown native error.'))
  }
}).catch(reject))).catch(error => {
  SystemLog.error(SystemLog.LogTypes.JSBridge, error.message)
  return { code: -1, msg: error && error.message || 'unknown error'  }
})

