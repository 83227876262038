import React from 'react';
import Switch from 'component/switch';
import { useWebView } from 'component/webview';
import './index.sass';

type ListItemLinkProps = {
  icon?:any
  name:string
  value?:string
  href:string
  point?:string
  onClick?:any
}

function ListLinkItem(props:ListItemLinkProps) {
  const { name, href, icon, value, point = '', onClick } = props
  return <a className="item item-link" href={href} onClick={onClick}>
    <div className="left">
      {icon || ''}
      <label>{name}</label>
    </div>
    <div className="right">
      <span className="value">{value}</span>
      {point ? <span className={`poi poi-${point}`} /> : <></>}
      <span className="arrow" />
    </div>
  </a>
}

function ListWebViewLinkItem(props:ListItemLinkProps) {
  const { Link } = useWebView()
  const { name, href, icon, value, point = '', onClick } = props
  return <Link className="item item-link" to={href} onClick={onClick}>
    <div className="left">
      {icon || ''}
      <label>{name}</label>
    </div>
    <div className="right">
      <span className="value">{value}</span>
      {point ? <span className={`poi poi-${point}`} /> : <></>}
      <span className="arrow" />
    </div>
  </Link>
}

type ListItemSwitchProps = {
  icon?:any
  name:string
  defaultValue:boolean
  onChange:any
}

function ListSwitchItem(props:ListItemSwitchProps) {
  const { defaultValue, name, icon, onChange } = props
  return <div className="item item-switch">
    <div className="left">
      {icon || ''}
      <label>{name}</label>
    </div>
    <div className="right">
      <Switch defaultValue={defaultValue} onChange={onChange} />
    </div>
  </div>
}

type ListNormalProps = {
  icon?:any
  name:string
  onClick:any
  display:boolean
}

function ListNormalItem(props:ListNormalProps) {
  const { name, icon, onClick, display } = props
  const style:any = {}
  if (!display) style.display = 'none'
  return <div className="item item-switch" onClick={onClick} style={style}>
    <div className="left">
      {icon || ''}
      <label>{name}</label>
    </div>
    <div className="right arrow" />
  </div>
}

type ListNativeLinkProps = {
  icon?:any
  name:string
  value?:string
  onClick:any
  point?:string
}

function ListNativeLinkItem(props:ListNativeLinkProps) {
  const { name, value = '', icon, onClick, point = '' } = props
  return <div className="item item-native-link" onClick={onClick}>
    <div className="left">
      {icon || ''}
      <label>{name}</label>
    </div>
    <div className="right" >
      <span className="value">{value}</span>
      {point ? <span className={`poi poi-${point}`} /> : <></>}
      <span className="arrow" />
    </div>
  </div>
}

export type ListItemProps = 
  {type:'link'} & ListItemLinkProps |
  {type:'webview-link'} & ListItemLinkProps |
  {type:'switch'} & ListItemSwitchProps |
  {type:'normal'} & ListNormalProps |
  {type:'native-link'} & ListNativeLinkProps

type ListProps = {
  list:ListItemProps[]
}

export default function List(props:ListProps) {
  const { list } = props

  function renderList(item:ListItemProps, i:number) {
    const { type, ...params } = item
    switch (type) {
      default:
        return ''
      case 'link':
        return <ListLinkItem {...params as ListItemLinkProps} key={i} />
      case 'webview-link':
        return <ListWebViewLinkItem {...params as ListItemLinkProps} key={i} />
      case 'native-link':
        return <ListNativeLinkItem {...params as ListNativeLinkProps} key={i} />
      case 'switch':
        return <ListSwitchItem {...params as ListItemSwitchProps} key={i} />
      case 'normal':
        return <ListNormalItem {...params as ListNormalProps} key={i} />
    }
  }

  return <div className="cmp cmp-list">
    {list.map(renderList)}
  </div>
}
